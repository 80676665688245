import React from 'react';
import PropTypes from 'prop-types';
import { Banner } from '../banner/Banner';
import { cssRule } from '../commons/CssRule';

// Cannot display Ads but can display other things like TDFS.
export function NoAdsPage({ landerConfig, queryConfig }) {

  // banner
  const bannerContent = <Banner landerConfig={ landerConfig } queryConfig={ queryConfig } />;

  // The TDFS style is included here, that way TdfsBanner remains style-less and consistent with our original design
  // to separate the layout from the CSS. This approach also allows us to keep all CSS in a single style tag.
  const pageStyle = (
    <style>
      {cssRule('body', [
        'margin: 0;',
        'padding: 0;',
        'background-color: #333;',
        'font-family: \'Arial\';'
      ])}
      {cssRule('#tdfsBanner', [
        'background:#00ff00;',
        'font-size: 15px;',
        'padding: .5em;',
        '-webkit-transition: padding 400ms;',
        'transition: padding 400ms;'
      ])}
      {cssRule('#tdfsBanner:hover', [
        'padding: .7em .5em;'
      ])}
      {cssRule('#tdfsLink', [
        'margin:0 auto;',
        'max-width: 960px;',
        'width: 95%;',
        'text-align: center;'
      ])}
      {cssRule('#tdfsLink a', [
        'color: #000;',
        'display: block;',
        'font-size: 1em;',
        'font-weight: 300;',
        'text-decoration: none;',
        'text-shadow:0 1px rgba(0,0,0,.5);'
      ])}
    </style>
  );

  return (
    <div id='noads'>
      {pageStyle}
      {bannerContent}
    </div>
  );
}


NoAdsPage.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired
};
