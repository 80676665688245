import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ParkingLander from './ParkingLander';

const renderAppComponent = () => {
  const root = document.getElementById('contentMain');
  ReactDOM.render(<ParkingLander />, root);
};

renderAppComponent();
export { renderAppComponent };
