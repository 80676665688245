import React from 'react';
import { AdSense, getAdSensePageType } from './utils/adSenseUtils';
import SimpleLayout from './layout/SimpleLayout';
import ParkWebLayout from './layout/ParkWebLayout';
import HegLayout from './layout/HegLayout';
import { Logger } from '../commons/Logger';
import {
  ErrorCode,
  EventType,
  QueryParam,
  Settings
} from '../commons/Constants';
import PropTypes from 'prop-types';
import { NoAdsPage } from './NoAdsPage';
import postEvent, { createBaseLanderEvent } from '../commons/LanderEvent';
import {getContent} from './content/Content';

// Using ES6 object destructuring to locally scope the variables without having to use props.landerConfig or props.queryConfig
export default function AdSensePage({ landerConfig, queryConfig }) {

  // check if pubId and DRID are present. Proceed with CAF request regardless of DRID status
  const adSense = landerConfig.adSense;

  if (!adSense.drid || !adSense.pubId) {
    Logger.error('pubid or drid is missing');
    const errorCode = adSense.drid ? ErrorCode.ERROR_NO_PUBID : ErrorCode.ERROR_NO_DRID;
    sendInvalidAdsenseParamEvent(errorCode, landerConfig, queryConfig);

    // No ads but can render TDFS and other stuff
    return <NoAdsPage landerConfig={ landerConfig } queryConfig={ queryConfig } />;
  }

  // Get AdSense page (related link or sponsored listings)
  const page = getAdSensePageType(queryConfig);
  // Get localized content for a given template and locale
  const content = getContent(landerConfig.lander.template, landerConfig.system);

  switch (landerConfig.lander.template) {
    case AdSense.template.SIMPLE_2C:
    case AdSense.template.SIMPLE_2C_LIGHT:
    case AdSense.template.LIGHT_BLUE:
    case AdSense.template.VERTICAL_LINES:
    case AdSense.template.SIMPLE_2C_EXPERIMENT:
      return (
        <SimpleLayout
          page={ page }
          landerConfig={ landerConfig }
          queryConfig={ queryConfig }
          content={ content }
        />
      );

    case AdSense.template.PARK_WEB:
      return (
        <ParkWebLayout
          page={ page }
          landerConfig={ landerConfig }
          queryConfig={ queryConfig }
          content={ content }
        />
      );
    case AdSense.template.PARK_WEB_HEG:
      return (
        <HegLayout
          page={ page }
          landerConfig={ landerConfig }
          queryConfig={ queryConfig }
          content={ content }
        />
      );

    default:
      // This should not happen, we select a DEFAULT temple while building landerConfig.
      Logger.warn('Unknown template ' + landerConfig.lander.template);
      return (
        <SimpleLayout
          page={ page }
          landerConfig={ landerConfig }
          queryConfig={ queryConfig }
          content={ content }
        />
      );
  }
}

AdSensePage.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired
};

// Error event if lander-param content is invalid (missing pubId, drid, etc.)
function sendInvalidAdsenseParamEvent(errorCode, landerConfig, queryConfig) {
  const event = createBaseLanderEvent(EventType.ERROR, landerConfig);
  event.errorCode = errorCode;
  event.sToken = queryConfig[QueryParam.S_TOKEN];
  postEvent(Settings.EVENT_PUBLISH_API, event);
}
