import React, { Component } from 'react';
import { Logger } from '../commons/Logger';
import PropTypes from 'prop-types';
import { FetchStatus, VERIZON_FEED_PATH } from '../commons/Constants';
import { getVerizonRSUri, getVerizonAdsUri } from './VerizonFeed';
import VerizonAds from './VerizonAds';

export default class VerizonRS extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetchStatus: FetchStatus.PENDING,
      verizonFeedResult: null,   // Feed result
      verizonDetailsUrl: '',
    };
  }

  componentDidMount() {
    const feedUrl = `${VERIZON_FEED_PATH}?${getVerizonRSUri(this.props.landerConfig.domain.rootDomain)}`;

    fetch(feedUrl, { method: 'GET', credentials: 'omit' })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            fetchStatus: FetchStatus.SUCCESS,
            verizonFeedResult: new DOMParser().parseFromString(response.text(), 'text/xml')
          });
        }
      })
      .catch(err => {
        Logger.error(err);
        this.setState({
          fetchStatus: FetchStatus.FAILURE
        });
      });
  }

  handleVersionDetails(val, rootDomain) {
    this.setState({
      verizonDetailsUrl: getVerizonAdsUri(val, rootDomain),
    })
  }

  getAddKeywords(verizonFeedResult) {
    let keywordArr = []
    try {
      const resultSet = (verizonFeedResult && verizonFeedResult.getElementsByTagName('ResultSet')[1]
        && verizonFeedResult.getElementsByTagName('ResultSet')[1].childNodes[1])
        ? verizonFeedResult.getElementsByTagName('ResultSet')[1].childNodes[1].children
        : []
      keywordArr = Array.from(resultSet);
    } catch (err) {
      Logger.error(err)
    }
    return keywordArr
  }

  mapKeywordsItems(items, rootDomain) {
    return (
      <ul>
        {
          // create related link items
          items.map((item, itemIndex) => {
            return (
              <li
                key={`verizon-feed-item-${itemIndex}`}
                onClick={() => { this.handleVersionDetails(item.getAttribute('queryParams'), rootDomain) }}
                style={{ color: '#fff', fontSize: 'x-large', fontWeight: 'bold', lineHeight: '2em' }}
              >
                {item.innerHTML}
              </li>
            )
          })
        }
      </ul>
    )
  }

  render() {
    if (this.state.fetchStatus === FetchStatus.PENDING) {
      return null;
    }

    return (
      <div id='verizon-feed'>
        {
          this.state.versionDetails
            ? this.mapKeywordsItems(this.getAddKeywords(this.state.verizonFeedResult), this.props.landerConfig.domain.rootDomain)
            : <VerizonAds adUrl={this.state.verizonDetailsUrl} />
        }
      </div>
    );
  }

}

VerizonRS.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired
};
