import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { AdSense } from './utils/adSenseUtils';
import callCaf from './utils/cafWrapper';

export default function AdSenseBlock({ landerConfig, queryConfig, page }) {
  useEffect(() => {
    callCaf(landerConfig, queryConfig, page);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let adsenseContentDivId = 'ads';
  if (page === AdSense.page.RELATED_LINKS) {
    adsenseContentDivId = 'relatedLinks';
  }

  return (
    <div id={ adsenseContentDivId }>
      <FormattedMessage id='adsBlockedText' />
    </div>
  );
}

AdSenseBlock.propTypes = {
  page: PropTypes.string.isRequired,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired
};
