import React from 'react';
import { Logger } from './Logger';
import {
  ErrorCode,
  EventType,
  QueryParam,
  Settings
} from './Constants';
import PropTypes from 'prop-types';
import { getDomainName } from './HelperFunctions';
import { createBaseLanderEvent } from './LanderEvent';
import { cssRule } from './CssRule';
import postEvent from './LanderEvent';

export class LanderError {
  constructor(code, msg) {
    this.code = code;
    this.message = typeof msg !== 'undefined' ? msg : '';

    if (!ErrorCode.hasOwnProperty(this.code)) {
      Logger.error('Error code ' + this.code + ' is unknown.');
    }
  }
}

// Client error page. Using ES6 object destructuring to locally scope the variables no longer having to prefix them with props
export function ErrorPage({
  errorCode,
  message,
  landerConfig,
  queryConfig,
  sendEvent
}) {
  const error = new LanderError(errorCode, message);
  Logger.error(error.code + ' ' + error.message);

  // Send ERROR Event if enabled (default is true)
  if (typeof sendEvent !== 'undefined' && sendEvent === true) {
    sendErrorEvent(landerConfig, queryConfig, errorCode);
  }

  const pageStyle = (
    <style>
      {cssRule('body', [
        'margin: auto;',
        'padding: 10px;',
        'background-color: #F3F7FC;',
        "font-family: 'Arial';"
      ])}
    </style>
  );

  return (
    <div>
      {pageStyle}
      <div id="errorMessage"/>
    </div>
  );
}

// Specifies the default values for props:
ErrorPage.defaultProps = {
  sendEvent: true
};

ErrorPage.propTypes = {
  errorCode: PropTypes.string.isRequired,
  message: PropTypes.string,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object,
  sendEvent: PropTypes.bool
};

// Publish lander error events
function sendErrorEvent(landerConfig, queryConfig, errorCode) {
  const event = createBaseLanderEvent(EventType.ERROR, landerConfig);
  event.errorCode = errorCode;
  event.sToken = (queryConfig || {})[QueryParam.S_TOKEN];

  // fill in domain name if lander-param API doesn't have it
  if (typeof event.domain === 'undefined') {
    event.domain = queryConfig
      ? getDomainName(queryConfig)
      : window.location.hostname;
  }
  postEvent(Settings.EVENT_PUBLISH_API, event);
}
