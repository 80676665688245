import React, { Component } from 'react';
import PropTypes from 'prop-types';
import postEvent, { createParkWebRedirectFosEvent } from '../commons/LanderEvent';
import { Settings } from '../commons/Constants';

export default class TargetedLander extends Component {

  componentDidMount() {
    const redirectEvent = createParkWebRedirectFosEvent(this.props.queryConfig);
    postEvent(Settings.EVENT_PUBLISH_API, redirectEvent);
  }

  render() {
    return (
      <iframe title='parked-content' width='100%' frameBorder='0' height={window.innerHeight + 'px'}
              src={ this.props.targeted.fosUrl }>
        Sorry your browser does not support inline frames.
      </iframe>
    );
  }

}

TargetedLander.propTypes = {
  targeted: PropTypes.object.isRequired,
  queryConfig: PropTypes.object
};
