import { getCookie, objectToQueryStringExcludeNull } from '../commons/HelperFunctions';
import { LanderCookie, Settings, VERIZON_FEED_PATH } from '../commons/Constants';

export function getVerizonRSUri(rootDomain) {
  const rsParams = {
    url: rootDomain,
    Partner: Settings.VERIZON_FEED_PARTNER,
    maxListings: 3,
    maxWeb: 0,
    maxRT: 6,
    maxRTL: 6,
    maxPC: 6,
    urlLang: 'en',
    affilData: {
      ip: getCookie(LanderCookie.CLIENT_IP),
      ua: navigator.userAgent,
      ur: document.referrer
    },
    serveUrl: `${window.location.protocol}//${rootDomain}`
  };
  return objectToQueryStringExcludeNull(rsParams);
}

export function getVerizonAdsUri(qs, rootDomain) {
  const adsParam = {
    url: rootDomain,
    Partner: Settings.VERIZON_FEED_PARTNER,
    affilData: {
      ip: getCookie(LanderCookie.CLIENT_IP),
      ua: navigator.userAgent,
      ur: document.referrer
    },
    serveUrl: `${window.location.protocol}//${rootDomain}`
  };
  const adsParamStr = objectToQueryStringExcludeNull(adsParam);
  return `${VERIZON_FEED_PATH}?${adsParamStr}&${qs}`;
}
