import PropTypes from 'prop-types';
import { Logger } from '../commons/Logger';

export default function ForSalePage() {

  Logger.debug('TODO - for sale layout');
  return null;    // TODO: TBD by UX

}

ForSalePage.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired
};
